import { useTranslation } from 'react-i18next';

import { usePocketCollections } from '../../hooks/pocket/queries/usePocketCollections';
import { findArtifact } from '../../pocket/artifacts';

export function useCollection(id: string) {
  const { t } = useTranslation();
  const { data } = usePocketCollections();
  if (data) {
    for (const item of data) {
      if (item.collectionId === id) {
        const artifact = findArtifact(item.type);
        return artifact ? {
          id: item.collectionId,
          title: t(artifact.titleKey),
          subtitle: t(artifact.shortDescriptionKey),
          description: t(artifact.descriptionKey),
          price: item.price,
          img: artifact.img.large,
          limit: artifact.limit,
          limitKey: artifact.limitKey,
          pocketType: artifact.pocketType,
        } : undefined;
      }
    }
  }
}