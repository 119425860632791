import { Trans } from 'react-i18next';

import { Callout } from '../Callout/Callout';

import { ReactComponent as PendingIcon } from '../../../svg/img/pending32.svg';

export function NonImmediateTransferCallout(props: { className?: string }) {
  return (
    <Callout
      {...props}
      Icon={PendingIcon}
      title={<Trans i18nKey="NonImmediateTransferCallout.Title"/>}
    >
      <Trans i18nKey="NonImmediateTransferCallout.Text"/>
    </Callout>
  );
}