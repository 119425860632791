import cn from 'classnames';

import { Button } from '../../pocket/Button/Button';
import { SubHeadline } from '../../../../uilib/Typography/Typography';
import { StreaksShimmer } from '../../../_components/StreaksShimmer/StreaksShimmer';
import { StreaksPanel } from '../../../_components/StreaksPanel/StreaksPanel';

import { ReactComponent as MinusIcon } from '../../../../svg/img/minus20.svg';
import { ReactComponent as PlusIcon } from '../../../../svg/img/plus20.svg';
import { ReactComponent as LockIcon } from '../../../../svg/img/lock20.svg';
import s from './CountPicker.module.scss';

export function CountPicker({
  onAddLockedClick,
  countSelected,
  onCountChanged,
  disabled,
  showControls,
}: {
  countSelected: number;
  onCountChanged(isAdd: boolean): void;
  onAddLockedClick?(): void;
  disabled: boolean;
  showControls: boolean;
}) {
  const removeDisabled = disabled || !countSelected;

  return (
    <StreaksPanel className={s.root}>
      {showControls && (
        <Button
          className={cn(s.button, s.buttonLeft, removeDisabled && s.buttonDisabled)}
          onClick={() => {
            onCountChanged(false);
          }}
          disableActiveState
          disabled={removeDisabled}
        >
          <MinusIcon/>
        </Button>
      )}
      <SubHeadline weight="semi-bold" level={1} className={s.count}>
        {countSelected}
      </SubHeadline>
      {showControls && (
        <Button
          className={cn(s.button, s.buttonRight, (onAddLockedClick || disabled) && s.buttonDisabled)}
          onClick={() => {
            onAddLockedClick
              ? onAddLockedClick()
              : onCountChanged(true);
          }}
          disabled={disabled}
          disableActiveState
        >
          {onAddLockedClick ? <LockIcon/> : <PlusIcon/>}
        </Button>
      )}
    </StreaksPanel>
  );
}

CountPicker.Shimmer = () => <StreaksShimmer className={s.shimmer} height={44}/>;