import { Section } from '../Section/Section';
import { TaskCompleted } from './components/TaskCompleted';
import { Task } from './components/Task/Task';
import { StreakAlmostLost } from './components/StreakAlmostLost/StreakAlmostLost';
import { useData } from './hooks/useData';
import { StreaksAnimatePresence } from '../../../../_components/StreaksAnimatePresence';
import { useIsSetOnce } from '../../../../hooks/useIsSetOnce';
import { StreakLost } from './components/StreakLost/StreakLost';

export function DailyTask() {
  const data = useData();
  const vanishedOnce = useIsSetOnce(!data);

  return (
    <StreaksAnimatePresence
      initial={{ marginTop: -12 }}
      animate={{ marginTop: 0 }}
      exit={{ marginTop: -12 }}
    >
      {(data && (data[0] !== 'loading' || !vanishedOnce)) && (
        <Section>
          {data[0] === 'task-completed'
            ? <TaskCompleted/>
            : data[0] === 'streak-almost-lost'
              ? <StreakAlmostLost livesRequired={data[1]} shouldPurchase={data[2]}/>
              : data[0] === 'streak-lost'
                ? <StreakLost/>
                : (
                  <Task
                    data={data[0] === 'loading' ? 'loading' : {
                      state: data[0],
                      task: data[1],
                    }}
                  />
                )}
        </Section>
      )}
    </StreaksAnimatePresence>
  );
}
