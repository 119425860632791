import type { StreaksTask } from './types';

/**
 * Converts a streak's task from the server to its local representation.
 * @param input - server task representation
 */
export function formatStreaksTask(input: {
  id: number;
  app: {
    id: number;
    attributes: {
      analytics_id?: Maybe<string>;
      title?: Maybe<string>;
      description?: Maybe<string>;
      url?: Maybe<string>;
      editors_choice?: Maybe<boolean>;
      webapp_url?: Maybe<string>;
      icon: {
        data: {
          attributes: {
            url: string;
            name?: Maybe<string>;
          }
        }
      };
    }
  },
  title?: Maybe<string>;
  description?: Maybe<string>;
  // fixme: should always exist
  startDate?: Maybe<Date>;
  endDate?: Maybe<Date>;
  rewards: {
    type: 'POINT' | 'BOOST' | 'LIVE' | 'SPIN' | 'NFT_TICKET' | string;
    value: number;
  }[];
}): StreaksTask {
  const { attributes } = input.app;

  return {
    ...input,
    analyticsId: attributes.analytics_id,
    app: {
      id: input.app.id,
      title: attributes.title,
      url: attributes.url,
      icon: attributes.icon.data.attributes,
      webappUrl: attributes.webapp_url,
      description: attributes.description,
      editorsChoice: attributes.editors_choice,
    },
  };
}