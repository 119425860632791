import { useMemo } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';

import { NavigationEvents } from './NavigationEvents';
import { Providers, type ProvidersProps } from './Providers';
import { useOnboardingCompleted } from '../pages/streaks/hooks/useOnboardingCompleted';
import { WithColoredUI } from './WithColoredUI';
import { Onboarding } from '../pages/streaks/components/Onboarding/Onboarding';
import { useAppStateContext } from '../providers/AppStateProvider/context';

export interface LayoutProps extends Omit<ProvidersProps, 'tonConnectReturnUrl'> {
  tonConnectReturnBaseUrl: string;
}

function Inner() {
  const {
    hide: hideOnboarding,
    isHidden: isOnboardingHidden,
    isLoading: isOnboardingHiddenLoading,
  } = useOnboardingCompleted();
  const { isInitialDataLoaded } = useAppStateContext();

  return (
    <>
      <NavigationEvents/>
      <ScrollRestoration/>
      {/* TODO: This logic should be moved to Root */}
      {isOnboardingHiddenLoading || !isInitialDataLoaded
        ? null
        : isOnboardingHidden ? <Outlet/> : (
          <WithColoredUI color="#fff">
            <Onboarding onClose={hideOnboarding}/>
          </WithColoredUI>
        )}
    </>
  );
}

export function Layout({ tonConnectReturnBaseUrl, ...rest }: LayoutProps) {
  const { pathname, search, hash } = useLocation();
  const tonConnectReturnUrl = useMemo(() => {
    const path = `${pathname}${search}${hash}`;
    const url = new URL(tonConnectReturnBaseUrl);
    url.searchParams.set('startapp', `page_${btoa(path)}`);

    return url.toString() as `${string}://${string}`;
  }, [tonConnectReturnBaseUrl, pathname, search, hash]);

  return (
    <Providers {...rest} tonConnectReturnUrl={tonConnectReturnUrl}>
      <Inner/>
    </Providers>
  );
}
