import type { ReactNode } from 'react';
import { useTonAddress } from '@tonconnect/ui-react';

import { WithColoredUI } from '../../_components/WithColoredUI';
import { useUserPocket } from '../../hooks/pocket/queries/useUserPocket';
import { StreakArtifacts } from './StreakArtifacts/StreakArtifacts';
import { Web3Artifacts } from './Web3Artifacts/Web3Artifacts';
import { Artifacts } from './Artifacts/Artifacts';

import styles from './PocketPage.module.scss';

export function PocketPage() {
  const { isLoading, data } = useUserPocket();
  const walletAddress = useTonAddress();

  let headers: ReactNode;
  if (isLoading) {
    headers = (
      <>
        <StreakArtifacts data="loading"/>
        <Web3Artifacts data="loading"/>
      </>
    );
  } else if (data) {
    headers = typeof data.spins === 'number' || typeof data.nftTickets === 'number' ? (
      <>
        <StreakArtifacts data={data}/>
        <Web3Artifacts data={{ ...data, walletAddress }}/>
      </>
    ) : <StreakArtifacts data={{ ...data, standalone: true }}/>;
  }

  return (
    <WithColoredUI color="#1C1C1E">
      <div className={styles.root}>
        {headers}
        <Artifacts/>
      </div>
    </WithColoredUI>
  );
}
