import type { HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';

import { Heading } from '../../../../../uilib/Typography/Typography';
import { PanelContent } from '../PanelContent/PanelContent';
import { Ripple } from '../../../../../uilib/Ripple/Ripple';

import { ReactComponent as CloseIcon } from '../../../../../svg/img/close-circle.svg';
import s from './Panel.module.scss';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: ReactNode;
  onClose?(): void;
}

export function Panel({ children, title, onClose, className, ...rest }: Props) {
  return (
    <div className={cn(s.root, className)} {...rest}>
      {!!(title || onClose) && (
        <PanelContent>
          <div className={s.header}>
            {title && (
              <Heading className={s.title} weight="bold" type="h4">
                {title}
              </Heading>
            )}
            {onClose && (
              <Ripple className={s.close} onClick={onClose}>
                <CloseIcon className={s.closeIcon}/>
              </Ripple>
            )}
          </div>
        </PanelContent>
      )}
      {children}
    </div>
  );
}