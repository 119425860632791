import type { StreaksTask } from '../../../helpers/types';
import { toUTCDay } from '../../../utils/dates';

export interface FormattedTask {
  analyticsId?: Maybe<string>;
  app: {
    id: number;
    title?: string | null;
    icon: {
      url: string;
      name?: string | null;
    };
    url: string;
    editorsChoice?: boolean | null;
    description?: string | null;
  };
  description?: string | null;
  endsAt?: Date | null;
  id: number;
  rewards: {
    type: 'POINT' | 'BOOST' | 'LIVE' | 'SPIN' | 'NFT_TICKET' | string;
    value: number;
  }[];
  // fixme: should always exist
  startsAt?: Date | null;
  title?: string | null;
}

export function formatTask(task: StreaksTask): FormattedTask | undefined {
  const { app, id: taskId } = task;
  if (app && taskId) {
    const { url } = app;
    if (url) {
      return {
        id: taskId,
        title: task.title,
        description: task.description,
        app: {
          id: app.id,
          title: app.title,
          icon: app.icon,
          url: app.webappUrl || url,
          editorsChoice: app.editorsChoice,
          description: app.description,
        },
        analyticsId: task.analyticsId,
        rewards: task.rewards,
        // Backend sends those 2 properties as dates, which are included in the completion interval.
        // So, if August 10 is specified as the end date, it means that the task can be completed
        // up to August 11, 00:00.
        // For better DX, we are transforming those 2 dates to more appropriate ones.
        startsAt: task.startDate ? new Date(toUTCDay(task.startDate)) : undefined,
        endsAt: task.endDate
          ? new Date(toUTCDay(task.endDate) + 24 * 60 * 60 * 1000)
          : undefined,
      };
    }
  }
}