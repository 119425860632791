import s from './CollectionImage.module.scss';

import { StreaksShimmer } from '../../../_components/StreaksShimmer/StreaksShimmer';

export interface CollectionImageProps {
  src: string;
  srcSet: string;
  width: number;
  height: number;
  background: {
    color: string;
    src: string;
    width: number;
    height: number;
    deltaX: number;
    deltaY: number;
  };
}

export function CollectionImage({ background, ...rest }: CollectionImageProps) {
  return (
    <div
      className={s.root}
      style={{ background: background.color }}
      onContextMenu={e => {
        e.preventDefault();
      }}
    >
      <img
        className={s.background}
        alt=""
        src={background.src}
        style={{
          width: background.width,
          height: background.height,
          transform: `translate3d(calc(-50% + ${background.deltaX}px), calc(-50% + ${background.deltaY}px), 0)`,
        }}
      />
      <img
        {...rest}
        alt=""
        className={s.image}
        style={{ width: rest.width, height: rest.height }}
      />
    </div>
  );
}

CollectionImage.Shimmer = () => <StreaksShimmer className={s.shimmer}/>;