import React, { useMemo } from 'react';
import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { Layout, type LayoutProps } from './Layout';
import IndexPage from '../pages/index/page';
import RecentPage from '../pages/recent/page';
import CategoryPage from '../pages/category/page';
import ApplicationPage from '../pages/application/page';
import ReferralProgramPage from '../pages/referralPrograms/page';
import { ReferralsPage } from '../pages/referralPrograms/referrals/page';
import { SettingsPage } from '../pages/settings/SettingsPage';
import { StreaksPage } from '../pages/streaks/StreaksPage';
import { PocketPage } from '../pages/pocket/PocketPage';
import { PocketCollectionPage } from '../pages/pocket-collection/PocketCollectionPage';

import { TWA_ROOT_PATH } from '../../utils/constatns';

import 'swiper/css';
import '../../styles/globals.scss';

export function App({
  tonConnectReturnBaseUrl,
  tonConnectManifestUrl,
  appsRanking,
  appConfig,
  navigationConfig,
  mainPageBlockConfig,
  categories,
}: LayoutProps) {
  const router = useMemo(() => {
    return createBrowserRouter([{
      id: 'root',
      element: (
        <Layout
          tonConnectReturnBaseUrl={tonConnectReturnBaseUrl}
          tonConnectManifestUrl={tonConnectManifestUrl}
          appsRanking={appsRanking}
          appConfig={appConfig}
          navigationConfig={navigationConfig}
          mainPageBlockConfig={mainPageBlockConfig}
          categories={categories}
        />
      ),
      children: [
        { path: '/', element: <IndexPage/> },
        { path: '/applications/:application', element: <ApplicationPage/> },
        { path: '/recent', element: <RecentPage/> },
        { path: '/settings', element: <SettingsPage/> },
        {
          path: '/referral-programs/:programId/referrals',
          element: <ReferralsPage/>,
          loader: (args) => {
            return Promise.resolve(args.params.programId ?? null);
          },
        },
        { path: '/referral-programs', element: <ReferralProgramPage/> },
        { path: '/streaks', element: <StreaksPage/> },
        { path: '/pocket', element: <PocketPage/> },
        { path: '/pocket/:collectionId', element: <PocketCollectionPage/> },
        { path: '/:category', element: <CategoryPage/> },
      ],
    }], { basename: TWA_ROOT_PATH });
  }, [
    tonConnectReturnBaseUrl,
    tonConnectManifestUrl,
    appsRanking,
    appConfig,
    navigationConfig,
    mainPageBlockConfig,
    categories,
  ]);

  return <RouterProvider router={router}/>;
}
