import { AnimatePresence, motion, type Target, type TargetAndTransition } from 'framer-motion';
import type { PropsWithChildren } from 'react';

import { useIsSetOnce } from '../hooks/useIsSetOnce';

export function StreaksAnimatePresence({
  children,
  className,
  animate,
  initial,
  exit,
}: PropsWithChildren<{
  className?: string;
  initial?: Target;
  animate?: TargetAndTransition;
  exit?: TargetAndTransition;
}>) {
  const hiddenOnce = useIsSetOnce(!children);

  return (
    <AnimatePresence>
      {children && (
        <motion.div
          key="inner"
          className={className}
          initial={hiddenOnce ? { height: 0, opacity: 0, scale: 0.9, ...initial } : false}
          animate={{ height: 'auto', opacity: 1, scale: 1, ...animate }}
          exit={{ height: 0, opacity: 0, scale: 0.9, ...exit }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}