import { array, boolean, date, mixed, number, object, string } from 'yup';

import { HttpClient } from '../../shared/http/HttpClient';
import { formatStreaksTask } from './formatStreaksTask';
import type {
  GetActiveOrderResponse,
  GetStreaksLeaguesResponse,
  LivesToTransactionResponse,
  PurchasePocketItemsItem,
  GetStreaksLeaderboardRankResponse,
  GetStreaksTasksResponse,
  GetStreaksUserStateResponse,
  GetUserPocketResponse,
  GetPocketCollectionsResponse,
  GetPocketCollectionResponse,
} from './types';
import { ApiError } from '../../shared/http/ApiError';

interface SchemaLike {
  validateSync(data: unknown): any;
}

function schemaToValidateFn<S extends SchemaLike>(schema: S): (data: unknown) => ReturnType<S['validateSync']> {
  return (data: unknown) => schema.validateSync(data);
}

export class ApiClient {
  private readonly httpClient: HttpClient;

  constructor(baseUrl: string, initData: string) {
    this.httpClient = new HttpClient(baseUrl, {
      headers: {
        'x-init-data': initData,
      },
    });
  }

  /**
   * Performs a GET request extracting the response data.
   * @param url - URL to send request to.
   * @param schema - validation schema.
   * @private
   */
  private get<S extends SchemaLike>(
    url: string,
    schema: S,
  ): Promise<ReturnType<S['validateSync']>> {
    return this.httpClient.get(url, schemaToValidateFn(schema)).then(r => r.data);
  }

  /**
   * Performs a POST request extracting the response data.
   * @param url - URL to send request to.
   * @param body - request body.
   * @param schema - validation schema.
   * @private
   */
  private post<S extends SchemaLike, Body = unknown>(
    url: string,
    body: Body,
    schema: S,
  ): Promise<ReturnType<S['validateSync']>> {
    return this.httpClient.post(url, body, schemaToValidateFn(schema)).then(r => r.data);
  }

  /**
   * Performs a PUT request extracting the response data.
   * @param url - URL to send request to.
   * @param body - request body.
   * @param schema - validation schema.
   * @private
   */
  private put<S extends SchemaLike, Body = unknown>(
    url: string,
    body: Body,
    schema: S,
  ): Promise<ReturnType<S['validateSync']>> {
    return this.httpClient.put(url, body, schemaToValidateFn(schema)).then(r => r.data);
  }

  /**
   * Completes the daily task.
   * @param id - task identifier.
   */
  async completeStreaksDailyTask(id: number): Promise<void> {
    await this.post(`/streaks/complete-task?id=${id}`, {}, mixed()).then();
  }

  /**
   * Retrieves the user active order.
   */
  getActiveOrder(): Promise<GetActiveOrderResponse> {
    return this.get('/pocket/active-order', object({
      data: object({
        status: string().defined(),
        statusDetails: object({
          code: string().nullable(),
        })
          .notRequired()
          .default(undefined),
      })
        .nullable()
        .optional(),
    }));
  }

  /**
   * @returns Project leagues list.
   */
  async getStreaksLeagues(): Promise<GetStreaksLeaguesResponse> {
    return this
      .get('/streaks/leagues', object({
        items: array()
          .of(
            object({
              id: number().required(),
              name: string().required(),
              streakDays: number().required(),
              participants: number().required(),
              additionalRewards: boolean().required(),
            }),
          )
          .defined(),
      }))
      .then(d => d.items);
  }

  /**
   * Returns the current user state in streaks context.
   */
  async getStreaksUserState(): Promise<GetStreaksUserStateResponse> {
    return this.get('/streaks/user-state', object({
      streakDays: number().defined(),
      latestCompletedTaskDate: date().nullable(),
      daysToNextLeague: number().defined(),
      dailyCompletedTasks: array().of(number().defined()).default([]),
      currentLeague: object({
        id: number().defined(),
        name: string().defined(),
        streakDays: number().defined(),
      })
        .nullable()
        .optional(),
      nextLeague: object({
        id: number().defined(),
        name: string().defined(),
        streakDays: number().defined(),
      })
        .nullable()
        .optional(),
    }));
  }

  /**
   * Returns user daily tasks.
   */
  async getStreaksTasks(): Promise<GetStreaksTasksResponse> {
    const taskSchema = object({
      id: number().defined(),
      app: object({
        id: number().defined(),
        attributes: object({
          analytics_id: string().nullable(),
          title: string().nullable(),
          description: string().nullable(),
          url: string().nullable(),
          editors_choice: boolean().nullable(),
          webapp_url: string().nullable(),
          icon: object({
            data: object({
              attributes: object({
                url: string().defined(),
                name: string(),
              }),
            }),
          }),
        }),
      }),
      title: string().nullable(),
      description: string().nullable(),
      // FIXME: start date should always exist.
      startDate: date().nullable(),
      endDate: date().nullable(),
      rewards: array()
        .of(
          object({
            type: string().defined(),
            value: number().defined(),
          }),
        )
        .defined(),
    })
      .nullable()
      .optional();

    const { main, additional } = await this.get('/streaks/daily-tasks', object({
      main: taskSchema,
      additional: array().of(taskSchema.required()).nullable(),
    }));

    return {
      main: main ? formatStreaksTask(main) : undefined,
      additional: additional ? additional.map(formatStreaksTask) : undefined,
    };
  }

  /**
   * Retrieves the user pocket information.
   */
  getUserPocket(): Promise<GetUserPocketResponse> {
    return this.get('/pocket/user', object({
      points: number().defined(),
      boosts: number(),
      lives: number().defined(),
      spins: number(),
      nftTickets: number(),
    }));
  }

  /**
   * Retrieves all known pocket collections.
   */
  getPocketCollections(): Promise<GetPocketCollectionsResponse> {
    return this.get('/pocket/collections', array().of(
      object({
        collectionId: string().required(),
        name: string().required(),
        description: string().required(),
        price: number().required(),
        type: string().required(),
      }),
    ).required());
  }

  /**
   * Retrieves a specific collection.
   * @param collectionId - collection identifier.
   */
  getPocketCollection(collectionId?: string): Promise<GetPocketCollectionResponse> {
    return this.get(`/pocket/collection?id=${collectionId}`,
      object({
        collectionId: string().required(),
        name: string().required(),
        description: string().required(),
        previews: array().of(
          object({
            url: string().required(),
            resolution: string().required(),
          }),
        ).optional(),
        price: number().required(),
        type: string().required(),
      }),
    );
  }

  /**
   * Returns the current user streaks leaderboard rank information.
   */
  getStreaksLeaderboardRank(): Promise<GetStreaksLeaderboardRankResponse> {
    return this.get('/leaderboard/rank', object({
      rank: number().required(),
    }));
  }

  /**
   * Restores the user streak using the lives purchase transaction ID.
   * @param transactionId - transaction ID.
   */
  restoreStreak(transactionId: number): Promise<void> {
    return this
      .post('/streaks/restore-streak', { transactionId }, object({
        error: object({
          statusCode: number().defined(),
          statusText: string().defined(),
          data: mixed(),
        })
          .notRequired()
          .default(undefined),
      }))
      .then(({ error }) => {
        if (error) {
          throw new ApiError(error.statusCode, error.statusText, error.data);
        }
      });
  }

  /**
   * Resets the streak.
   */
  resetStreak(): Promise<void> {
    return this.post('/streaks/reset', {}, mixed()).then();
  }

  /**
   * Converts lives to transaction.
   * @param livesCount - count of lives to pack.
   */
  livesToTransaction(livesCount: number): Promise<LivesToTransactionResponse> {
    return this.post('/pocket/lives-to-transaction', { livesCount }, object({
      data: object({
        transactionId: number().defined(),
      })
        .notRequired()
        .default(undefined),
      error: object({
        statusCode: number().defined(),
        statusText: string().defined(),
        data: mixed(),
      })
        .notRequired()
        .default(undefined),
    }))
      .then(({ error, data }) => {
        if (error) {
          throw new ApiError(error.statusCode, error.statusText, error.data);
        }
        if (!data) {
          throw new Error('Unexpected response. Data is missing');
        }
        return data;
      });
  }

  /**
   * Purchases specified pocket items and returns transaction information.
   * @param wallet - wallet address.
   * @param items - purchased items.
   */
  purchasePocketItems(wallet: string, items: PurchasePocketItemsItem[]): Promise<void> {
    return this.post('/pocket/purchase', { wallet, items }, mixed()).then();
  }

  /**
   * Registers the user wallet in our system.
   * @param address - wallet address.
   */
  registerWallet(address: string): Promise<void> {
    return this.put('/pocket/register-wallet', { address }, mixed()).then();
  }

  /**
   * Signs the user in.
   */
  signIn(): Promise<void> {
    return this.post('/auth/sign-in', {}, mixed()).then();
  }
}