import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTonConnect } from '../../providers/TonConnectProvider/context';
import { useUserPocket } from '../../hooks/pocket/queries/useUserPocket';
import { useHapticFeedback } from '../../hooks/useHapticFeedback';
import { useShowAlert } from '../../hooks/useShowAlert';
import { useCollection } from './useCollection';
import { usePendingOrder } from './usePendingOrder';
import { useActiveOrder } from './useActiveOrder';
import { usePurchasePocketItems } from '../../hooks/pocket/mutations/usePurchasePocketItems';
import { useMainButton } from './useMainButton';
import { WithColoredUI } from '../../_components/WithColoredUI';
import { CollectionImage, type CollectionImageProps } from './CollectionImage/CollectionImage';
import { TitlePanel, type TitlePanelProps } from './TitlePanel/TitlePanel';
import { DescriptionPanel } from './DescriptionPanel/DescriptionPanel';
import { CountPicker } from './CountPicker/CountPicker';
import { StreaksAnimatePresence } from '../../_components/StreaksAnimatePresence';
import {
  NonImmediateTransferCallout,
} from '../../_components/NonImmediateTransferCallout/NonImmediateTransferCallout';

import s from './PocketCollectionPage.module.scss';

// TODO: This hooks is common among the pocket collection page and pocket page. It should be
//  moved to some common place.
function useAlerts() {
  const { t } = useTranslation();
  const hapticFeedback = useHapticFeedback();
  const showAlert = useShowAlert();

  const showErrorAlert = useCallback((text: string) => {
    hapticFeedback.notificationOccurred('error');
    showAlert(text);
  }, [hapticFeedback, showAlert]);

  return {
    showInsufficientFundsAlert: useCallback(() => {
      showErrorAlert(t('PocketPage.AlertInsufficientFunds'));
    }, [showErrorAlert, t]),
    showLimitReachedAlert: useCallback((translationKey: string) => {
      showErrorAlert(t(translationKey));
    }, [showErrorAlert, t]),
    showPurchaseCompletedAlert: useCallback(() => {
      hapticFeedback.notificationOccurred('success');
      showAlert(t('PocketPage.PurchaseCompleted'));
    }, [t, hapticFeedback, showAlert]),
    showOrderFailedAlert: useCallback((reason: 'unknown' | 'limit-reached') => {
      showErrorAlert(
        t(reason === 'unknown'
          ? 'PocketPage.AlertOrderFailedUnknown'
          // FIXME: Temporarily leaving this key here for release. We should define a more
          //  generic message probably.
          : 'PocketPage.AlertLotteryTicketsLimitReached'),
      );
    }, [showErrorAlert, t]),
  };
}

interface DataProps extends TitlePanelProps {
  id: string;
  img: CollectionImageProps;
  description: string;
  limit?: Maybe<number>;
  limitKey?: Maybe<string>;
  countAcquired: number;
  userPoints: number;
  isPurchaseAllowed: boolean;
  isDelayedTransfer: boolean;
}

function Data({
  title,
  description,
  subtitle,
  id,
  price,
  limitKey,
  limit,
  countAcquired,
  userPoints,
  img,
  isPurchaseAllowed,
  isDelayedTransfer,
}: DataProps) {
  const { wallet } = useTonConnect();
  const { mutate: mutateUserPocket } = useUserPocket();
  const hapticFeedback = useHapticFeedback();
  const [countSelected, setCountSelected] = useState(
    isPurchaseAllowed ? 0 : countAcquired,
  );

  // Handlers showing alerts.
  const {
    showLimitReachedAlert,
    showInsufficientFundsAlert,
    showPurchaseCompletedAlert,
    showOrderFailedAlert,
  } = useAlerts();

  const [hasActiveOrder, { set: setHasActiveOrder }] = usePendingOrder();
  useActiveOrder({
    hasPendingOrder: !!hasActiveOrder.data,
    onPending() {
      setHasActiveOrder(true);
    },
    onCompleted() {
      setHasActiveOrder(false);

      // Revalidate user pocket information and reset selected items.
      void mutateUserPocket();
      setCountSelected(0);

      // Making notification asynchronous as long as calling it right away will prevent
      // the Main Button from hiding. resetSelected will drop selected items, but then
      // the alert will be shown preventing the code from getting to the Main Button state
      // update. As a result, the Main Button will be updated only when the alert was hidden.
      setTimeout(showPurchaseCompletedAlert, 100);
    },
    onFailed(reason: 'unknown' | 'limit-reached') {
      setHasActiveOrder(false);

      showOrderFailedAlert(reason);

      // If a limit was reached, it seems like we have an actual state of the user pocket,
      // so we have to revalidate it.
      if (reason === 'limit-reached') {
        void mutateUserPocket();
      }
    },
    onMissing() {
      setHasActiveOrder(false);
    },
  });

  const {
    trigger: purchasePocketItems,
    isMutating: isPurchasingPocketItems,
  } = usePurchasePocketItems({
    onSuccess() {
      // After the purchase was completed, we expect a new active order to appear.
      setHasActiveOrder(true);
    },
  });

  useMainButton({
    canShow: isPurchaseAllowed,
    isLoading: isPurchasingPocketItems || !!hasActiveOrder.data,
    totalPrice: countSelected * price,
    purchase: useCallback(() => {
      wallet && purchasePocketItems({
        wallet: wallet.address,
        items: [{ collectionId: id, count: countSelected }],
      });
    }, [wallet, purchasePocketItems, id, countSelected]),
  });

  // Whenever the count acquired and collection limit change, we should actualize the selected
  // count to prevent the user from selecting more items than allowed.
  useEffect(() => {
    if (limit) {
      const canAcquire = Math.max(limit - countAcquired, 0);
      setCountSelected(selected => Math.min(canAcquire, selected));
    }
  }, [countAcquired, limit]);

  return (
    <>
      <CollectionImage {...img}/>
      <TitlePanel title={title} price={price} subtitle={subtitle}/>
      <StreaksAnimatePresence
        initial={{ marginBottom: 0 }}
        animate={{ marginBottom: 20 }}
        exit={{ marginBottom: 0 }}
      >
        {(wallet || !isPurchaseAllowed) && (
          <>
            {(isPurchaseAllowed || countAcquired > 0) && (
              <CountPicker
                countSelected={countSelected}
                disabled={isPurchasingPocketItems || !!hasActiveOrder.data}
                onCountChanged={isAdd => {
                  hapticFeedback.selectionChanged();
                  setCountSelected(count => count + (isAdd ? 1 : -1));
                }}
                showControls={isPurchaseAllowed}
                onAddLockedClick={
                  // Check if the limit was reached.
                  countSelected >= (limit ? limit - countAcquired : Number.POSITIVE_INFINITY)
                    ? () => {
                      limitKey && showLimitReachedAlert(limitKey);
                    }
                    // Check if the user has enough funds to add one more item.
                    : (countSelected + 1) * price > userPoints
                      ? showInsufficientFundsAlert
                      : undefined
                }
              />
            )}
            {isPurchaseAllowed && isDelayedTransfer
              ? <NonImmediateTransferCallout/>
              : undefined}
          </>
        )}
      </StreaksAnimatePresence>
      <DescriptionPanel>{description}</DescriptionPanel>
    </>
  );
}

export function PocketCollectionPage() {
  const { data: userPocket } = useUserPocket();
  const collection = useCollection(useParams().collectionId || '');
  const isTicket = collection ? collection.pocketType === 'nftTickets' : false;

  return (
    <WithColoredUI color="#1C1C1E">
      <main className={s.root}>
        {collection && userPocket ? (
          <Data
            {...collection}
            countAcquired={(
              collection.pocketType
                ? userPocket[collection.pocketType]
                : undefined
            ) || 0}
            userPoints={userPocket.points}
            isPurchaseAllowed={!isTicket}
            isDelayedTransfer={isTicket}
          />
        ) : (
          <>
            <CollectionImage.Shimmer/>
            <TitlePanel.Shimmer/>
            <CountPicker.Shimmer/>
            <DescriptionPanel.Shimmer/>
          </>
        )}
      </main>
    </WithColoredUI>
  );
}