/**
 * Processes a start parameter as a deep linking instruction.
 * @param startParam - start parameter value.
 */
export function processStartParamAsDeeplink(startParam: string): void {
  const { history } = window;
  const root = '/twa';
  const parameters = startParam.split('-');

  /**
   * @returns A list of parameters without specified parameter.
   * @param key - parameter to remove
   */
  const withoutParameter = (key: string): string => {
    return parameters
      .filter(p => p.split('_')[0] !== key)
      .join('-');
  };

  /**
   * @returns Parameter values.
   * @param key - parameter key
   */
  const getParameter = (key: string): string[] | undefined => {
    for (const parameter of parameters) {
      const parts = parameter.split('_');
      if (parts[0] === key) {
        return parts.length > 1 ? parts.slice(1) : undefined;
      }
    }
  };

  const hSetRoot = () => {
    history.replaceState(null, '', root);
  };

  const hPush = (path: string) => {
    history.pushState(null, '', `${root}${path}`);
  };

  const ecoValues = getParameter('eco');
  if (ecoValues) {
    const [eco] = ecoValues;
    eco === 'ton' && (window.ecosystem = eco);
  }

  if (startParam === 'streaks') {
    hSetRoot();
    hPush('/streaks');
    return;
  }

  const appValues = getParameter('app');
  if (appValues) {
    hSetRoot();
    hPush(`/applications/${appValues[0]}?tgWebAppStartParam=${withoutParameter('app')}`);
  }

  const sectionValues = getParameter('section');
  if (sectionValues) {
    const query = `?tgWebAppStartParam=${withoutParameter('section')}`;
    hSetRoot();

    const [section] = sectionValues;
    if (section === 'pocket') {
      hPush('/streaks');
      hPush(`/pocket${query}`);
    } else {
      hPush(`/${section}${query}`);
    }
    return;
  }

  const pageValues = getParameter('page');
  if (pageValues) {
    const query = `?tgWebAppStartParam=${withoutParameter('page')}`;
    try {
      const page = atob(pageValues[0]);
      hSetRoot();
      hPush(`/${page}${query}`);
    } catch {
    }
  }
}