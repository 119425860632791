import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';

import { openTelegramLink } from '../../../../utils/common';

/**
 * @returns function which should be called whenever a task application should be opened.
 */
export function useOnTaskAppOpened() {
  return useCallback(({ id, app, type }: {
    id: number;
    type: 'main' | 'additional',
    app: {
      id: number;
      title?: string | null;
      webappUrl?: string | null;
      url?: string | null;
    }
  }) => {
    mixpanel.track('App Launch', {
      app_name: app.title,
      app_id: app.id,
      section: 'Streaks',
      task_type: type,
    });

    mixpanel.track('task_started', {
      id,
      app_name: app.title,
      task_type: type === 'main' ? 'daily' : 'additional',
    });
    const url = app.webappUrl || app.url;
    url && openTelegramLink(url);
  }, []);
}
