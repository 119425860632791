import { Trans } from 'react-i18next';
import { useCallback } from 'react';
import { useFlag } from '@unleash/proxy-client-react';

import { StreaksShimmer } from '../../../../../../_components/StreaksShimmer/StreaksShimmer';
import { TWAApplicationCard } from '../../../../../../../uilib/ApplicationCard/TWAApplicationCard';
import {
  StreaksTimerDaily,
} from '../../../../../../_components/StreaksTimerDaily/StreaksTimerDaily';
import { Footnote } from '../../../../../../../uilib/Typography/Typography';
import { useOnTaskAppOpened as _useOnTaskAppOpened } from '../../../../hooks/useOnTaskAppOpened';
import {
  useCompleteStreaksDailyTask,
} from '../../../../../../hooks/streaks/useCompleteStreaksDailyTask';
import { Panel } from '../../../Panel/Panel';
import { PanelContent } from '../../../PanelContent/PanelContent';
import { useCloudStorageKey } from '../../../../../../providers/CloudStorageProvider/context';
import { useStreaksUserState } from '../../../../../../hooks/streaks/useStreaksUserState';
import { useNow } from '../../../../../../hooks/dates/useNow';
import type { FormattedTask } from '../../../../utils/formatTask';

import { ReactComponent as VerifyIcon } from '../../../../../../../svg/img/verifycheck.svg';
import s from './Task.module.scss';

function TaskShimmer() {
  return (
    <Panel
      className={s.root}
      title={<StreaksShimmer height={24}/>}
    >
      <PanelContent className={s.appShimmer}>
        <StreaksShimmer className={s.appShimmerIcon}/>
        <div className={s.appShimmerInfo}>
          <StreaksShimmer width={90} height={22}/>
          <StreaksShimmer width={140} height={16}/>
          <StreaksShimmer width={60} height={18}/>
        </div>
      </PanelContent>
      <PanelContent className={s.footer}>
        <StreaksShimmer width="100%" height="100%" rounded/>
      </PanelContent>
    </Panel>
  );
}

interface TaskDataProps {
  state: 'completed' | 'verifying';
  task: FormattedTask;
}

function TaskData({ state, task }: TaskDataProps) {
  const completeDailyTask = useCompleteStreaksDailyTask();
  const onTaskAppOpened = _useOnTaskAppOpened();
  const { mutate: mutateUserState } = useStreaksUserState();
  const [, setDailyTaskOpenedAt] = useCloudStorageKey('dailyTaskOpenedAt');
  const now = useNow();
  const enableTaskCompletionWithAnalytics = useFlag('enableTaskCompletionWithAnalytics');

  const onOpened = useCallback(() => {
    // Only tasks with a missing analyticsId should be completed in-place.
    // Having this property, it is completed on the app's side.
    if (!enableTaskCompletionWithAnalytics || !task.analyticsId) {
      // Fake completion date.
      mutateUserState(prev => {
        return prev ? { ...prev, latestCompletedTaskDate: now } : prev;
      }, { revalidate: false });

      // Set task open date in Cloud Storage.
      setDailyTaskOpenedAt(now.toISOString());

      // Trigger task completion.
      completeDailyTask.trigger(task.id).catch((e) => {
        // Reset the state in case something went wrong.
        console.error('Error completing task', e);
        setDailyTaskOpenedAt('');
      });
    }

    // Open the app and send stats.
    onTaskAppOpened({ ...task, type: 'main' });
  }, [
    task,
    onTaskAppOpened,
    completeDailyTask,
    setDailyTaskOpenedAt,
    now,
    mutateUserState,
    enableTaskCompletionWithAnalytics,
  ]);

  const { app } = task;
  const { icon } = app;

  return (
    <Panel
      className={s.root}
      title={<Trans i18nKey="StreaksPage.DailyTaskTitle"/>}
    >
      <TWAApplicationCard
        title={app.title || undefined}
        editorsChoice={app.editorsChoice || undefined}
        description={app.description || undefined}
        img={{ src: icon.url, name: icon.name || '' }}
        overrideOnClick={onOpened}
        onButtonClick={onOpened}
      />
      <PanelContent className={s.footer}>
        {state === 'verifying' ? (
          <div className={s.verifying}>
            <VerifyIcon className={s.footerVerifyingIcon}/>
            <Footnote weight="medium">
              <Trans i18nKey="StreaksPage.DailyTaskVerifying"/>
            </Footnote>
          </div>
        ) : <StreaksTimerDaily/>}
      </PanelContent>
    </Panel>
  );
}

export function Task({ data }: { data: 'loading' | TaskDataProps }) {
  return data === 'loading' ? <TaskShimmer/> : <TaskData {...data}/>;
}
