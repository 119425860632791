import type { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { StreaksShimmer } from '../../../../_components/StreaksShimmer/StreaksShimmer';
import { StreaksPanel } from '../../../../_components/StreaksPanel/StreaksPanel';
import { Footnote, Heading, SubHeadline } from '../../../../../uilib/Typography/Typography';
import { Button } from '../../Button/Button';
import { SystemRipple } from '../../../../_components/SystemRipple/SystemRipple';

import { ReactComponent as MinusIcon } from '../../../../../svg/img/minus20.svg';
import { ReactComponent as PlusIcon } from '../../../../../svg/img/plus20.svg';
import { ReactComponent as LockIcon } from '../../../../../svg/img/lock20.svg';
import s from './Artifact.module.scss';

export function ArtifactShimmer() {
  return (
    <StreaksPanel className={s.root}>
      <div className={s.imageWrap}>
        <StreaksShimmer height="100%"/>
      </div>
      <div className={s.content} style={{ paddingBottom: 12 }}>
        <StreaksShimmer style={{ marginBottom: 4 }} width="95%" height={24}/>
        <StreaksShimmer style={{ marginBottom: 4 }} width="70%" height={16}/>
        <StreaksShimmer style={{ marginBottom: 22 }} width="30%" height={16}/>
        <StreaksShimmer width="100%" height={30} rounded/>
      </div>
    </StreaksPanel>
  );
}

interface ArtifactImageProps {
  src: string;
  srcSet: string;
  width: number;
  height: number;
  background: {
    color: string;
    src: string;
    width: number;
    height: number;
    deltaX: number;
    deltaY: number;
  };
}

interface ArtifactTextsProps {
  title: string;
  description: string;
}

function ArtifactImage({ background, ...rest }: ArtifactImageProps) {
  return (
    <div
      className={s.imageWrap}
      style={{ background: background.color }}
      onContextMenu={e => {
        e.preventDefault();
      }}
    >
      <img
        className={s.imageBg}
        alt=""
        src={background.src}
        style={{
          width: background.width,
          height: background.height,
          transform: `translate3d(calc(-50% + ${background.deltaX}px), calc(-50% + ${background.deltaY}px), 0)`,
        }}
      />
      <img
        {...rest}
        alt=""
        className={s.image}
        style={{ width: rest.width, height: rest.height }}
      />
    </div>
  );
}

function ArtifactTexts({ title, description }: ArtifactTextsProps) {
  return (
    <div className={s.content}>
      <div>
        <Heading weight="semi-bold" type="h4">{title}</Heading>
        <Footnote className={s.description} weight="regular">
          {description}
        </Footnote>
      </div>
    </div>
  );
}

export function Artifact({
  canAdd,
  id,
  countSelected,
  onCountChanged,
  title,
  price,
  img,
  description,
  onLockedClick,
  onAddLockedClick,
  _pickerLockedText,
}: {
  id: string;
  title: string;
  description: string;
  price: number;
  img: ArtifactImageProps;
  canAdd: boolean;
  countSelected: number;
  onCountChanged(id: string, isAdd: boolean): void;
  onLockedClick?(): void;
  onAddLockedClick?(): void;
  // TODO: Crutch
  _pickerLockedText?: ReactNode;
}) {
  const navigate = useNavigate();

  return (
    <StreaksPanel className={s.root}>
      <SystemRipple
        className={s.inner}
        onClick={() => {
          navigate(`/pocket/${id}`);
        }}
      >
        <ArtifactImage {...img}/>
        <ArtifactTexts title={title} description={description}/>
      </SystemRipple>
      <div className={s.control}>
        {onLockedClick || (!countSelected && canAdd) ? (
          <Button
            className={cn(s.button, onLockedClick && s.buttonDisabled)}
            onClick={() => {
              onLockedClick ? onLockedClick() : onCountChanged(id, true);
            }}
            disableActiveState
            rounded
          >
            <SubHeadline className={s.priceText} weight="semi-bold" level={1}>
              {onLockedClick && _pickerLockedText
                ? _pickerLockedText
                : <Trans i18nKey="PocketPage.ArtifactPricePoints" count={price}/>}
            </SubHeadline>
            {onLockedClick && <LockIcon className={s.buttonLock}/>}
          </Button>
        ) : (
          <div className={s.countControls}>
            <Button
              className={s.countControl}
              onClick={() => {
                onCountChanged(id, false);
              }}
              disabled={!countSelected}
            >
              <MinusIcon/>
            </Button>
            <SubHeadline weight="semi-bold" level={1} className={s.countSelected}>
              {countSelected}
            </SubHeadline>
            <Button
              className={cn(s.countControl, !canAdd && s.countControlDisabled)}
              onClick={() => {
                canAdd
                  ? onCountChanged(id, true)
                  : onAddLockedClick && onAddLockedClick();
              }}
            >
              {canAdd ? <PlusIcon/> : <LockIcon/>}
            </Button>
          </div>
        )}
      </div>
    </StreaksPanel>
  );
}